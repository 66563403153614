.playStart {
    animation: playAnimation 1300ms infinite;
}

@keyframes playAnimation {
    0% {
        background-color: #9FAEB5;
        box-shadow: 0 0 5px #9FAEB5;
    }
    50% {
        background-color: #9DADB5;
        box-shadow: 0 0 20px #9DADB5;
    }
    100% {
        background-color: #9FAEB5;
        box-shadow: 0 0 5px #9FAEB5;
    }
}
