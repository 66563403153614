.flex-out {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
}

.flex-in {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex-start {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.flex-end {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.row-start {
	display: flex;
	justify-content: flex-start;
}

.row-end {
	display: flex;
	justify-content: flex-end;
}

.row-in {
	display: flex;
	justify-content: center;
}

.row-out {
	display: flex;
	justify-content: space-between;
}

.col-start {
	display: flex;
	align-items: flex-start;
}

.col-end {
	display: flex;
	align-items: flex-end;
}

.col-in {
	display: flex;
	align-items: center;
}

.col-out {
	display: flex;
	align-items: stretch;
}

.flex-col {
	display: flex;
	flex-direction: column;
}

.flex-col1 {
	display: flex;
	flex-direction: column;
    align-items: flex-start;
}

.flex-col2 {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.flex-col3 {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.flex-col4 {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.flex-col5 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.flex-col6 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
}

.flex-col7 {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.flex-col8 {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
}

.flex-col9 {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
}

.flex-row1 {
	display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.flex-row2 {
	display: flex;
    align-items: flex-start;
	justify-content: center;
}

.flex-row3 {
	display: flex;
    align-items: flex-start;
	justify-content: flex-end;
}

.flex-row4 {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.flex-row5 {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex-row6 {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.flex-row7 {
	display: flex;
	align-items: flex-end;
}

.flex-row8 {
	display: flex;
	align-items: flex-end;
	justify-content: center;
}

.flex-row9 {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}

.flex-wrap {
	flex-wrap: wrap;
}

.fit-rel {
	position: relative;
}

.fit-abs1 {
	position: absolute;
	top: 0;
	left: 0;
}

.fit-abs2 {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
}

.fit-abs3 {
	position: absolute;
	top: 0;
	right: 0;
}

.fit-abs4 {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}

.fit-abs5 {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.fit-abs6 {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
}

.fit-abs7 {
	position: absolute;
	left: 0;
	bottom: 0;
}

.fit-abs8 {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}

.fit-abs9 {
	position: absolute;
	right: 0;
	bottom: 0;
}

.fit-out {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.fit-row {
	position: absolute;
	left: 0;
	right: 0;
}

.fit-col {
	position: absolute;
	top: 0;
	bottom: 0;
}

.fit-fixed {
	position: fixed;
	z-index: 9999;
}

.wh-all {
	width: 100%;
	height: 100%;
}

.w-all {
	width: 100%;
}

.h-all {
	height: 100%;
}

.w-auto {
    width: auto;
}

.h-auto {
	height: auto;
}

.wh-v {
    width: 100vw;
    height: 100vh;
}

.h-vh {
    height: 100vh;
}

.w-vw {
    width: 100vw;
}

.h-vh50 {
    height: 50vh;
}

.wh-max {
	width: max-content;
	height: max-content;
}

.w-max {
	width: max-content;
}

.h-max {
	height: max-content;
}

.scroll-y {
	overflow-y: scroll;
	overflow-x: hidden;
}

.scroll-x {
	overflow-y: hidden;
	overflow-x: scroll;
}

.scroll-h {
	overflow: hidden;
}

@media only screen and (max-width: 750px) {
	.fit-pc {
		display: none !important;
	}
}

@media only screen and (min-width: 751px) {
	.fit-h5 {
		display: none !important;
	}
}