@font-face {
    font-family: 'Big Caslon';
    src: url("./front/BigCaslon.ttf") format('ttf');
}

@font-face {
    font-family: 'Baskerville';
    src: url("./front/Baskerville.ttc") format('ttc');
}

@font-face {
    font-family: 'BaskervilleB';
    src: url("./front/Baskerville-Bold.ttf");
}
