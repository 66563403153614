html,
body {
    position: relative;
    height: 100%;
    overflow: hidden;
    background: #E5E5E5;
}

#root {
    width: 100%;
    height: 100%;
}

.page-index {
    overflow-x: hidden;
    overflow-y: scroll;
}

.index6 .swiper-slide {
    opacity: 0 !important;
}

.index6 .swiper-slide-active {
    opacity: 1 !important;
}

.unitBox {
    visibility: hidden;
}

.load {
    opacity: 1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: url(../img/load.png) no-repeat;
    background-size: cover;
    z-index: 9;
}

.load .box {
    animation: opacityAnimation0 .5s 5.5s linear forwards;
}

.load .bg {
    display: block;
    width: 18rem;
    height: 18.8rem;
    flex-shrink: 0;
    background: url(../img/load-bg.png) no-repeat;
    background-size: 100% 100%;
    animation: rotateAnimation_ 2s linear 2;
}

.load .bg-abs {
    animation: opacityAnimation0 .5s 4s linear forwards;
}

.load .bg2 {
    opacity: 0;
    display: block;
    width: 22.6rem;
    height: 20rem;
    flex-shrink: 0;
    background: url(../img/load-bg2.png) no-repeat;
    background-size: 100% 100%;
    animation: opacityAnimation1 .5s 4s linear forwards;
}

.load .icon {
    display: block;
    width: 13.8rem;
    height: 13.8rem;
    background: url(../img/load-icon.png) no-repeat;
    background-size: 100% 100%;
    animation: rotateAnimation 2s linear 2;
}

.load .title {
    opacity: 0;
    display: none;
    overflow: hidden;
    color: #FFF;
    text-overflow: ellipsis;
    font-family: "Big Caslon";
    font-size: 20rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    text-transform: uppercase;
    mix-blend-mode: lighten;
    animation: topAnimation .5s 5.5s linear forwards;
}

.load .text {
    top: calc(50% + 10rem);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    color: #464D4E;
    text-align: center;
    text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.60);
    font-family: Baskerville;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

@keyframes topAnimation {
    from {
        top: 25%;
        opacity: 0;
    }

    to {
        top: 50%;
        opacity: .8;
    }
}

@keyframes opacityAnimation0 {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes opacityAnimation1 {
    from {
        opacity: 0;
        transform: scale(.8);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes rotateAnimation_ {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-360deg);
    }
}

@keyframes rotateAnimation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.index1 {
    height: 72rem;
    background-image: url(../img/unit1/bg.png);
    background-size: cover;

    padding-top: 22.9rem;
}

.index1 #canvas_index1 {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: none;
}

.index1 .bgU {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.index1 .bgU i {
    border-radius: 50%;
    display: block;
    position: absolute;
    left: 20%;
    bottom: 0;
    width: 0.2rem;
    height: 0.2rem;
    flex-shrink: 0;
    background-color: #FFF;
    box-shadow: 0 0 .4rem #FFF;
    animation: animUp 50s linear 10s forwards;
}

@-webkit-keyframes animUp {
    0% {
        opacity: 1;
        bottom: 0;
    }

    90% {
        opacity: 1;
        bottom: 100%;
    }

    100% {
        opacity: 0;
        bottom: 100%;
    }
}

@keyframes animUp {
    0% {
        opacity: 1;
        bottom: 0;
    }

    90% {
        opacity: 1;
        bottom: 100%;
    }

    100% {
        opacity: 0;
        bottom: 100%;
    }
}


.index1 .title {
    height: 11.5rem;
    text-align: center;
    overflow: hidden;
    color: #464D4E;
    text-overflow: ellipsis;

    /* Title 1 */
    font-family: "Big Caslon";
    font-size: 9.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    /* 11.52rem */
    text-transform: uppercase;
}

.index1 .icon {
    width: 36rem;
    height: 3rem;
    display: block;
    margin: 0 auto;
}

.index1 .label {
    opacity: .8;
    width: 60rem;
    margin: 2rem auto 0;
    color: #464D4E;
    text-align: center;
    text-shadow: 0rem 0rem 2rem rgba(255, 255, 255, 0.60);

    /* Body 1 */
    font-family: Baskerville;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 2.24rem */
}

.index1 .label1 {
    margin-top: 2.4rem;
    color: #464D4E;
    text-align: center;
    text-shadow: 0rem 0rem 2rem rgba(255, 255, 255, 0.60);

    font-family: BaskervilleB;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: .01rem;
}

.index1 .playButton {
    margin: -2rem auto;
    display: block;
    width: 34.8rem;
    height: 15.8rem;
    stroke-width: .1rem;
    stroke: #464D4E;
    filter: drop-shadow(0rem 0rem 2.5rem rgba(0, 0, 0, 0.15)) drop-shadow(0rem 0rem 1.5rem rgba(255, 255, 255, 0.50));
    background: url(../img/unit1/play_btn.png) no-repeat;
    background-size: auto 100%;
    background-position: center;
    color: #464D4E;
    text-align: center;
    font-family: BaskervilleB;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: 15.8rem;
    transition: .3s all;
}

.index1 .playButton:hover {
    background-image: url(../img/unit1/play_btn_hover.png);
    font-size: 2.8rem;
}

.index2 {
    height: 76rem;
    background-image: url(../img/unit2/bg_top.png), url(../img/unit2/bg_btoom.png);
    background-size: 100% auto, 100% auto;
    background-position: 0 0, bottom left;
    background-repeat: no-repeat;
    position: relative;
    background-color: #EAEAEA;
}

.index2 .bg_icon {
    position: absolute;
    top: 50%;
    left: 1.7rem;
    transform: translateY(-50%);
    width: 20.8rem;
    height: 68.3rem;
    flex-shrink: 0;
}

.index2 .popU {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    background: url(../img/unit2/bg2.png) no-repeat;
    background-size: 87.9rem 26.8rem;
    background-position: right 7rem;

    opacity: 0;
}

.index2 .popU .item {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

/* .index2.active .list .item {
    opacity: 0;
} */

/* .index2.active .bg {
    opacity: 1;
} */

/* .index2.active .item .jdt_list {
    opacity: 1;
    transition: .5s all;
} */

/* .index2.active .item_active {
    opacity: 1;
    transition: .5s all;
} */

.index2 .item4 .item_active canvas {
    top: calc(50% - 4rem);
    width: 170% !important;
    height: 170% !important;
}

.index2 .item_active canvas {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 140% !important;
    height: 140% !important;
}

.index2 .item2 .item_active canvas {
    top: calc(50% - 3rem);
}

.index2 .item3 .item_active canvas {
    top: calc(50% - 3rem);
}

.index2.active .item .img_bg .item_button {
    background-image: url(../img/unit2/btn_item_active.png);
    background-size: 100% 100%;
    color: #FFF;
    transition: .5s all;
}

.index2.active .back {
    display: flex;
    z-index: 3;
}

.index2 .bg {
    /* opacity: 0; */
    transition: 1s all;
    pointer-events: none;
}

.index2 .back {
    cursor: pointer;
    position: absolute;
    top: 4rem;
    right: 4rem;
    width: 6rem;
    height: 6rem;
    flex-shrink: 0;

    background: url(../img/unit2/closeIcon.png) no-repeat;
    background-size: 100% 100%;

    /* cursor: pointer;
    position: absolute;
    top: 4rem;
    right: 8rem;
    z-index: 2;
    display: none;
    align-items: center;
    color: #464D4E;
    font-family: Baskerville;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    text-transform: uppercase;
    gap: .8rem; */
}

.index2 .back .arrow {
    width: 6rem;
    height: 6rem;
}

.index2 .item_active {
    /* opacity: 0; */
    position: absolute;
    top: 50%;
    left: 50%;
    /* transform: translateX(100%); */
    width: 60rem;
    height: 80rem;
    overflow: hidden;
    transform: translate(-50%, -50%);
}

.index2 .continter {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding-top: 7rem;
}

.index2 .next-btn {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    display: block;
    background-image: url(../img/next_btn.png);
    background-size: 100% 100%;
    width: 10rem;
    height: 10rem;


}

.index2 .title {
    margin: 0 auto;
    text-align: center;
    color: #464D4E;
    font-family: Baskerville;
    font-size: 2rem;
    font-style: italic;
    font-weight: 600;
    line-height: 160%;
    text-transform: uppercase;
    width: 49.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: none;
}

.index2 .list {
    padding-top: 12rem;
    position: relative;
    display: flex;
    /* margin-top: 16.4rem; */
    overflow-x: scroll;
    padding-left: 14.5rem;
    overflow: visible;
    left: 0;

    background: url(../img/unit2/bg_icon.png) no-repeat;
    background-size: 20.8rem 68.3rem;
    background-position: 1.7rem center;
}

.index2 .list::-webkit-scrollbar {
    display: none;
}

.index2 .list .item {
    width: 48rem;
    height: 56rem;
    position: relative;
    flex-shrink: 0;
    cursor: pointer;
    left: 0;
    margin-left: -20rem;
    overflow: hidden;
    transition: .3s all;
    /* opacity: 1; */
}

.index2 .swiper-slide {
    opacity: 0 !important;
}

.index2 .swiper-slide-active {
    opacity: 1 !important;
}

.index2.active .popU {
    opacity: 1;
    transition: .5s all .7s;
}

.index2.active .list {
    background: none;
}

.index2.active .list .item {
    opacity: 0;
}

.index2.active1 .list .item1 {
    opacity: 1;
    display: block;
    transition: all .5s linear 0;
    z-index: 2;
}

.index2.active2 .list .item2 {
    opacity: 1;
    display: block;
    transition: all .5s linear .3s;
    z-index: 2;
}

.index2.active3 .list .item3 {
    opacity: 1;
    display: block;
    transition: all .5s linear .5s;
    z-index: 2;
}

.index2.active4 .list .item4 {
    opacity: 1;
    display: block;
    transition: all .5s linear .7s;
    z-index: 2;
}

.index2.active1 .list {
    left: -7.5rem;
    transition: all .2s linear;
}

.index2.active2 .list {
    left: -35.5rem;
    transition: all .3s linear;
}

.index2.active3 .list {
    left: -63.5rem;
    transition: all .5s linear;
}

.index2.active4 .list {
    left: -91.5rem;
    transition: all .7s linear;
}

.index2 .list .item .item_hvr {
    position: absolute;
    top: 0;
    left: 50%;
    transform: skewX(-22.5deg) translateX(-15.5rem);
    content: '';
    display: block;
    width: 25rem;
    height: 100%;
    z-index: 1;
}

.index2 .list .item:nth-of-type(1) {
    margin-left: 0;
}

.index2 .item .img_bg {
    width: 48rem;
    height: 56rem;
    background-image: url(../img/unit2/bg_item.png);
    background-size: 100% 100%;
    position: relative;
    margin-left: -3rem;
    pointer-events: none;
}

.index2 .item .img_box {
    position: relative;
    /* width: 31rem; */
    height: 100%;
    overflow: hidden;
    transform: skewX(-22.5deg) translateX(11.5rem);
    position: relative;
}

.index2 .item .img_bg .img {
    position: absolute;
    top: 0;
    object-position: top;
    transform: skewX(22.5deg) translateX(-11.5rem);
    width: 100%;
    height: 100%;
    display: block;
    opacity: .5;
}

.index2 .item .img_bg .img_hvr {
    position: absolute;
    top: 0;
    left: 25%;
    opacity: 0;
    object-position: top;
    transform: skewX(22.5deg) translateX(-14.5rem);
    width: 72rem;
    height: 108rem;
    display: block;
}

.index2 .item .item_button {
    background-image: url(../img/unit2/btn_item.png);
    background-size: 100% 100%;
    display: block;
    width: 18.8rem;
    height: 4.8rem;
    position: absolute;
    left: 4rem;
    bottom: 2rem;
    color: #464D4E;
    text-align: center;
    font-family: BaskervilleB;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 4.8rem;
    text-transform: uppercase;
}

.index2 .item .jdt_list {
    position: absolute;
    right: 12rem;
    top: 20.4rem;

    width: 28rem;
    padding: 4rem 3rem 3.8rem;
    border-radius: 1.6rem;
    background: linear-gradient(180deg, #464D4E 0%, rgba(162, 178, 180, 0.80) 100%);
    box-shadow: .4rem .4rem 2rem 0rem rgba(255, 255, 255, 0.40) inset;
    backdrop-filter: blur(1rem);
}

.index2 .item .jdt_list .doc {
    color: #FFF;
    font-family: Baskerville;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    position: relative;
    overflow: hidden;
}

.index2 .item .jdt_list .item_button {
    position: absolute;
    left: 50%;
    top: 0;
    bottom: auto;
    margin: auto;
    transform: translate(-50%, -50%);
}

.index2 .item .item_hvr:hover + .img_bg .item_button {
    background-image: url(../img/unit2/btn_item_active.png);
    background-size: 100% 100%;
    color: #FFF;
    transition: .5s all;
}

/* .index2.active .item {
    overflow: visible;
} */

.index2.active .item .img_bg .img,
.index2 .item .item_hvr:hover + .img_bg .img {
    opacity: 1;
    transition: .5s all;
}

.index2 .item1 .img_bg .img_hvr {
    top: -32.6rem;
    left: -34.6rem;
    width: 112.4rem;
    height: 168.7rem;
    transform: skewX(22.5deg) translateX(-7.5rem);
}

.index2 .item2 .img_bg .img_hvr {
    top: -7.8rem;
    left: -1.3rem;
    width: 72rem;
    height: 108rem;
    transform: skewX(22.5deg) translateX(-9rem);
}

.index2 .item3 .img_bg .img_hvr {
    top: -2.9rem;
    left: -8.7rem;
    width: 88.1rem;
    height: 132.2rem;
    transform: skewX(22.5deg) translateX(-3rem);
}

.index2 .item4 .img_bg .img_hvr {
    top: -12.2rem;
    left: -5.8rem;
    width: 82rem;
    height: 123rem;
    transform: skewX(22.5deg) translateX(-9rem);
}

.index2 .item1 .item_active img {
    position: absolute;
    width: 56.3rem;
    height: 84.6rem;
    left: -1.7rem;
    top: -8.6rem;
}

.index2 .item2 .item_active img {
    position: absolute;
    width: 51.3rem;
    height: 77.1rem;
    left: 4.4rem;
    top: 1.4rem;
}

.index2 .item3 .item_active img {
    position: absolute;
    width: 44.3rem;
    height: 66.4rem;
    left: 6.8rem;
    top: 7.8rem;
}

.index2 .item4 .item_active img {
    position: absolute;
    width: 51.9rem;
    height: 77.8rem;
    left: 4rem;
    top: .7rem;
}

.index2 .popU .prevBtn {
    z-index: 2;
    cursor: pointer;
    width: 6rem;
    height: 6rem;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 4rem;
    background-image: url(../img/unit2/iconPrev.png);
    background-size: 100% 100%;
}

.index2 .popU .nextBtn {
    z-index: 2;
    cursor: pointer;
    width: 6rem;
    height: 6rem;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 4rem;
    background-image: url(../img/unit2/iconNext.png);
    background-size: 100% 100%;
}

.index2 .popU .nextBtn.disabled,
.index2 .popU .prevBtn.disabled {
    opacity: 0;
    pointer-events: none;
}

.index3 {
    height: 83rem;
    background-image: url(../img/unit3/bg.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
}

.index3 #canvas_index3 {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: none;
}

.index3 .continter {
    padding-top: 4.4rem;
    width: 100%;
    position: relative;
    margin: 0 auto;
}

.index3 .next-btn {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    display: block;
    background-image: url(../img/next_btn.png);
    background-size: 100% 100%;
    width: 10rem;
    height: 10rem;
}

.index3 .title {
    text-align: center;
    color: #FFF;
    font-family: Baskerville;
    font-size: 2rem;
    font-style: italic;
    font-weight: 600;
    line-height: 150%;
    text-transform: uppercase;
    text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.80), 0px 0px 10px rgba(255, 255, 255, 0.25);
}

.index3 .title span:nth-child(2n) {
    color: #FAECC1;
}

.index3 .swiper {
    overflow: visible;
}

.index3 .banner {
    padding: 0 12rem;
    margin-top: 8rem;
}

.index3 .banner .item {
    position: relative;
    width: 96rem;
    height: 48rem;
}

.index3 .banner .item .name {
    position: absolute;
    top: .8rem;
    left: 50%;
    transform: translateX(-50%);
    height: 11.5rem;
    line-height: 11rem;
    background-image: url(../img/unit3/title_bg.png);
    background-size: 100% 100%;
    width: 29.3rem;
    color: #FFF;
    text-align: center;
    font-family: BaskervilleB;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    /* 2.24rem */
}

.index3 .banner .img {
    display: block;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
}

.index3 .banner .img1 {
    background-image: url(../img/unit3/image1.png);
    background-size: 100% 100%;
}

.index3 .banner .img2 {
    background-image: url(../img/unit3/image2.png);
    background-size: 100% 100%;
}

.index3 .banner .img3 {
    background-image: url(../img/unit3/image3.png);
    background-size: 100% 100%;
}

.index3 .banner .img4 {
    background-image: url(../img/unit3/image4.png);
    background-size: 100% 100%;
}

.index3 .banner .img5 {
    background-image: url(../img/unit3/image5.png);
    background-size: 100% 100%;
}

.index3 .banner .img6 {
    background-image: url(../img/unit3/image6.png);
    background-size: 100% 100%;
}

.index3 .banner .img7 {
    background-image: url(../img/unit3/image7.png);
    background-size: 100% 100%;
}

.index3 .banner .img8 {
    background-image: url(../img/unit3/image8.png);
    background-size: 100% 100%;
}

.index3 .swiper-notification {
    display: none;
}

.index3 .banner .index3-pagination {
    display: flex;
    justify-content: center;
    gap: 2.9rem;
    position: absolute;
    left: 50%;
    bottom: -4rem;
    transform: translate(-50%, 100%);
    z-index: 2;
}

.index3 .banner .index3-pagination .swiper-pagination-bullet {
    width: 1.2rem;
    height: 1.2rem;
    transform: rotate(45deg);

    border: .1rem solid #464D4E;
    background-color: transparent;
    border-radius: 0;
    opacity: 1;
}

.index3 .banner .index3-pagination .swiper-pagination-bullet-active {
    background-color: #464D4E;
}

.index4 {

    background-color: #E5E5E5;
}

.index4 .continter {
    width: 100%;
    height: 84rem;
    margin: 0 auto;
    position: relative;
}

.index4 .smallBgL {
    background-image: url(../img/unit4/bg_iconL.png);
    background-size: 100% 100%;
    width: 28rem;
    height: 28rem;
    flex-shrink: 0;
    position: absolute;
    bottom: 12rem;
    left: 0rem;
}

.index4 .smallBg {
    background-image: url(../img/unit4/small_bg.png);
    background-size: 100% 100%;
    width: 15.3252rem;
    height: 15.3252rem;
    flex-shrink: 0;
    position: absolute;
    top: 14.377rem;
    right: 9.867rem;
}

.index4 .continter > .title {
    padding-top: 10rem;
    width: 100%;
    height: 22rem;
    margin: 0 auto;
    object-fit: contain;
    object-position: bottom;
    background: url(../img/title.png) no-repeat;
    background-size: 100% auto;
    /* width: ; */
    color: #82898A;
    font-family: Baskerville;
    font-size: 2rem;
    font-style: italic;
    font-weight: 600;
    line-height: 150%;
    text-align: center;
    /* 3rem */
    text-transform: uppercase;
}

.index4 .list {
    display: flex;
    width: 94.8rem;
    gap: 2.4rem;
    margin: 0rem auto 0;
}

.index4 .list .item {
    width: 30rem;
    height: 54.4rem;
    background-image: url(../img/unit4/item_bg.png);
    background-size: 100% 46.8rem;
    background-repeat: no-repeat;
    position: relative;
}

.index4 .list .item .icon {
    display: block;
    width: 18rem;
    height: 18rem;
    margin: 2rem auto 0;
}

.index4 .list .item .title {
    color: #464D4E;
    text-align: center;
    font-family: BaskervilleB;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-transform: uppercase;
    margin-top: 1rem;
}

.index4 .list .item .label {
    margin: .4rem auto 0;
    width: 24rem;
    color: #464D4E;
    text-align: center;
    font-family: BaskervilleB;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    /* 1.96rem */
    /* 2.24rem */
}

.index4 .list .item:first-child .label {
    font-size: 1.6rem;
    line-height: 135%;
}

.index4 .list .item .bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.index4 .list .item .bottom .bottomIcon {
    display: block;
    width: 2.6rem;
    height: 2.6rem;
    margin: 0 auto;
}

.index4 .tips {
    color: #464D4E;
    font-family: Baskervilleb;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    /* 1.96rem */
    text-align: center;
    margin-top: .3rem;
    text-transform: uppercase;
}

.index5 {
    background: url(../img/unit5/bg.png) no-repeat;
    background-size: cover;
    /* background-color: #E5E5E5; */
}

.index5 .continter {
    width: 100%;
    height: 90rem;
    margin: 0 auto;
    position: relative;
}

.index5 .continter > .title {
    padding-top: 10rem;
    width: 100%;
    height: 22rem;
    margin: 0 auto;
    object-fit: contain;
    object-position: bottom;
    background: url(../img/title.png) no-repeat;
    background-size: 100% auto;
    /* width: ; */
    color: #82898A;
    font-family: Baskerville;
    font-size: 2rem;
    font-style: italic;
    font-weight: 600;
    line-height: 150%;
    text-align: center;
    text-transform: uppercase;
}

.index5 .gamePlayer {
    cursor: pointer;
    width: 120rem;
    height: 60rem;
    border-radius: 1.6rem;
    overflow: hidden;
    background-color: #000000;
    box-shadow: 0 1rem 6rem 0 #464D4E;
    position: relative;
    margin: 0 auto;

}


.index5 .gamePlayer .playBtn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 6rem;
    height: 6rem;
    flex-shrink: 0;
    background: url(../img/unit5/playBtn.png) no-repeat;
    background-size: 100% 100%;
}


.index5 .gamePlayer .playBtn.pause {
    display: none;
    background: url(../img/unit5/pauseBtn.png) no-repeat;
    background-size: 100% 100%;
}

.index6 canvas {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 200%;
    height: 200%;
}

.index6 .pop {
    z-index: 3;
}

.index6 .pop .bg {
    position: absolute;
    top: 23rem;
    right: 0;
    display: block;
    width: 111rem;
    height: 58rem;
    background-image: url(../img/unit6/bg3.png);
    background-size: 111rem 58rem;
}

.index6 .pop .closeBtn {
    cursor: pointer;
    position: absolute;
    background-image: url(../img/unit6/closeIcon.png);
    background-size: 100% 100%;
    width: 6rem;
    height: 6rem;
    top: 20rem;
    right: 27.1rem;
    z-index: 2;
}

.index6 .pop .prevBtn {
    cursor: pointer;
    width: 6rem;
    height: 6rem;
    display: block;
    position: absolute;
    top: 43rem;
    left: 10rem;
    background-image: url(../img/unit6/iconPrev.png);
    background-size: 100% 100%;
}

.index6 .pop .nextBtn {
    cursor: pointer;
    width: 6rem;
    height: 6rem;
    display: block;
    position: absolute;
    top: 43rem;
    right: 10rem;
    background-image: url(../img/unit6/iconNext.png);
    background-size: 100% 100%;
}

.swiper-index6 {
    overflow: visible;
    width: 70rem;
    height: max-content;
    min-height: 24rem;
    margin: 35rem auto 0;
}

.swiper-index6 .content {
    display: flex;
    gap: 4rem;
    align-items: center;
}

.swiper-index6 .content .icon {
    position: relative;
    width: 24rem;
    height: 24rem;
    flex-shrink: 0;
}

.swiper-index6 .content .text .title {
    overflow: hidden;
    color: #464D4E;
    font-family: BaskervilleB;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    letter-spacing: -.045rem;
}

.swiper-index6 .content .text .label {
    margin-top: 1.4rem;
    color: #464D4E;
    font-family: BaskervilleB;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 134%;
    overflow: hidden;
}

.index6 {
    height: 92rem;
    background-color: #E5E5E5;
    position: relative;
    background-image: url(../img/unit6/bg.png);
    background-size: cover;
    background-repeat: no-repeat;

}

.index6 .continter {
    width: 100%;
    margin: 0 auto;
}

.index6 .continter > .title {
    padding-top: 10rem;
    width: 100%;
    height: 26rem;
    margin: 0 auto;
    object-fit: contain;
    object-position: bottom;
    background: url(../img/title.png) no-repeat;
    background-size: 100% auto;
    /* width: ; */
    color: #82898A;
    font-family: Baskerville;
    font-size: 2rem;
    font-style: italic;
    font-weight: 600;
    line-height: 150%;
    text-align: center;
    /* 3rem */
    text-transform: uppercase;
}

.index6 .iconBase {
    position: absolute;
    bottom: 0;
    right: -32rem;
    width: 100%;
    height: 30rem;
    background: url(../img/unit6/bg2.png) no-repeat;
    background-size: 100% 100%;
}


.index6 .box {
    width: 100%;
    /* margin-left: 17.7rem; */
    display: flex;
    justify-content: space-between;
}

.index6 .box .left {
    position: relative;
    height: max-content;
    margin-top: -4rem;
    z-index: 1;

    width: 60rem;
    flex-shrink: 0;
}

.index6 .box .left .item {
    position: relative;
    cursor: pointer;
    padding: 4rem 4rem 4rem 0;
    text-align: right;
}

.index6 .box .left .item .icon {
    pointer-events: none;
    width: 22rem;
    height: 22rem;
    right: 1.8rem;
    opacity: 0;
}

.index6 .box .left .item .txt {
    position: relative;
    color: #32393A;
    font-family: BaskervilleB;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-transform: uppercase;
    text-shadow: 0px 0px 4px rgba(70, 77, 78, 0.25);
}

.index6 .box .left .item3 .bg {
    letter-spacing: -0.4rem;
}

.index6 .box .left .item .bg {
    text-align: right;
    -webkit-text-stroke-width: .05rem;
    -webkit-text-stroke-color: #A0A7A8;
    font-family: Baskerville;
    font-size: 4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    color: transparent;
    opacity: 0;
}

.index6 .box .left .item:not(:last-child) {
    background: url(../img/unit6/item_gap.png) no-repeat;
    background-size: auto .1rem;
    background-position: right bottom;
}

.index6 .box .right {
    position: relative;
    width: 72.9005rem;
    height: 52rem;
    margin-top: -12rem;
    margin-bottom: 24rem;
    margin-right: 5rem;
}

.index6 .box .right .icon {
    position: absolute;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    display: block;
}

.index6 .box .item:hover {
    padding-right: 2.4rem;
    transition: .3s all;
}

.index6 .box .left .item:hover .icon {
    opacity: .4;
    transition: .3s all;
}

.index6 .box .left .item:hover .bg {
    opacity: 1;
    transition: .3s all;
}

.index7 {
    background-image: url(../img/unit7/bg.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    /* background-color: red; */
}

.index7 .continter {
    padding-bottom: 4.2rem;
    display: flex;
    padding-top: 8rem;
    width: 120rem;
    /* background-color: #E5E5E5; */
    position: relative;

    background-repeat: no-repeat;
    margin: 0 auto;
}

.index7 .form {
    display: flex;
    align-items: flex-end;
}

.index7 .form .formItem .label {
    color: #464D4E;
    font-family: BaskervilleB;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: .01rem;
}

.index7 .form .formItem .input {
    width: 32rem;
    height: 5rem;
    flex-shrink: 0;
    border-radius: 1.2rem;
    background: #D2D2D2;
    box-shadow: 0rem .6rem 1.2rem -0.6rem rgba(255, 255, 255, 0.12), 0rem .8rem 2.4rem -0.4rem rgba(255, 255, 255, 0.08);
    outline: none;
    border: none;
    color: #000000;
    font-family: BaskervilleB;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    padding: 0 1.6rem;
    margin-top: 1rem;
    letter-spacing: .01rem;
}

.index7 .form .formItem .input::placeholder {
    color: #A0A7A8;
}

.index7 .form .submit {
    cursor: pointer;
    margin-left: 1.6rem;
    display: flex;
    height: 5rem;
    border: none;
    padding: .5rem 2.6rem;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    color: #FFF;
    text-align: center;
    font-family: "Iowan Old Style";
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 2.4rem */
    text-transform: uppercase;
    border-radius: 1.2rem;
    background: #464D4E;
    box-shadow: .4rem .4rem 2rem 0rem rgba(255, 255, 255, 0.60) inset;
    /* align-self: stretch; */
}

.index7 .continter .link {
    display: flex;
    margin-left: 10rem;
    margin-top: 4.1rem;
    display: flex;
    gap: 4rem;
}

.index7 .continter .link .icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
}

.index7 .continter .copyRight {
    color: #464D4E;
    font-family: BaskervilleB;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    flex: 1;
    text-align: right;
    margin-top: 4.1rem;
    /* 2.4rem */
}

@media (hover: hover) {
}

@media only screen and (max-width: 750px) {

    .load .title {
        font-size: 15rem;
    }

    .index1 {
        height: 160rem;
        background-image: url(../img/unit1/bg_h5.png);
        background-size: cover;
        padding-top: 46.8rem;
    }

    .index1 .title {
        font-size: 12.8rem;
        height: 15.4rem;
    }

    .index1 .icon {
        width: 53.8rem;
        height: 4.4rem;
    }

    .index1 .label {
        width: 60.6rem;
        margin-top: 4rem;
        font-size: 2.8rem;
    }

    .index1 .label1 {
        font-size: 2.8rem;
        line-height: 120%;
    }

    .index1 .playButton {
        font-size: 4rem;
        margin-top: 3rem;
        width: auto;
        height: 26.15rem;
        line-height: 26.15rem;
    }

    .index1 .playButton:hover {
        background-image: url(../img/unit1/play_btn_hover.png);
        font-size: 4.8rem;
    }

    .index2 {
        height: 110rem;
    }

    .index2.active {
        height: 190rem;
    }

    .index2 .bg {
        height: 100rem;
        width: max-content;
        margin-left: -10rem;
        top: 24rem;
        transform: translate(-50%, 0);
    }

    .index2 .continter {
        padding-top: 7rem;
    }

    .index2 .popU {
        background-position: right 15rem;
    }

    .index2.active .back {
        top: 12rem;
    }

    .index2 .next-btn {
        width: 16rem;
        height: 16rem;
    }

    .index2 .title {
        font-size: 2.4rem;
    }

    .index2 .list {
        width: 100%;
        height: calc(100% - 17rem);
        overflow-x: scroll;
        padding-left: 15rem;
    }

    .index2.active .list {
        display: none;
    }

    .index2 .list::-webkit-scrollbar {
        display: none;
    }

    .index2 .list .item {
        width: 75rem;
        height: auto;
        margin-left: -35rem;
    }

    .index2 .item .jdt_list {
        display: flex;
        width: 60rem;
        padding: 8rem 4.8rem;
    }

    .index2 .item .img_bg .img {
        width: 72rem;
        height: 84rem;
        flex-shrink: 0;
    }

    .index2 .item .img_bg {
        width: 72rem;
        height: 84rem;
        flex-shrink: 0;
    }

    .index2 .list .item .item_hvr {
        width: 37.5rem;
        height: 82rem;
        transform: skewX(-22.5deg) translateX(-24.5rem);
    }

    .index2 .item .item_button {
        width: 29.6rem;
        height: 7.6rem;
        flex-shrink: 0;
        font-size: 3.2rem;
        line-height: 7.6rem;
        left: 6rem;
    }

    .index2.active .item {
        overflow: hidden;
        margin-left: 0;
    }

    .index2.active .item .img_bg {
        opacity: 0;
    }

    .index2 .item_active {
        width: 75rem;
        height: 100rem;
        top: 24rem;
        left: 0;
        transform: translate(0);
    }

    .index2 .item1 .item_active img {
        left: 0rem;
        top: -10.28rem;
        width: 70.25rem;
        height: 105.375rem;
    }

    .index2 .item2 .item_active img {
        left: 5.5rem;
        top: 1.5rem;
        width: 70.25rem;
        height: 105.375rem;
    }

    .index2 .item3 .item_active img {
        left: 9.75rem;
        top: 8.5rem;
        width: 55.375rem;
        height: 83rem;
    }

    .index2 .item4 .item_active img {
        left: 5rem;
        top: .876rem;
        width: 64.875rem;
        height: 97.25rem;
    }

    .index2 .item .jdt_list {
        left: 7.5rem;
        bottom: 20rem;
        top: auto;
        width: 60rem;
        height: 48rem;
        padding: 8rem 4.8rem;
    }

    .index2 .item .jdt_list .doc {
        font-size: 2.8rem;
    }

    .index2.active .contentBox .item {
        transition: all .5s linear 0s !important;
    }

    .index3 {
        height: 118rem;
    }

    .index3 .next-btn {
        width: 16rem;
        height: 16rem;
    }

    .index3 .title {
        font-size: 2.4rem;
    }

    .index3 .continter {
        padding-top: 7rem;
    }

    .index3 .banner .item {
        width: 60rem;
        height: 80rem;
    }

    .index3 .banner .img1 {
        background-image: url(../img/unit3/image1_h5.png);
        background-size: 100% 100%;
    }

    .index3 .banner .img2 {
        background-image: url(../img/unit3/image2_h5.png);
        background-size: 100% 100%;
    }

    .index3 .banner .img3 {
        background-image: url(../img/unit3/image3_h5.png);
        background-size: 100% 100%;
    }

    .index3 .banner .img4 {
        background-image: url(../img/unit3/image4_h5.png);
        background-size: 100% 100%;
    }

    .index3 .banner .img5 {
        background-image: url(../img/unit3/image5_h5.png);
        background-size: 100% 100%;
    }

    .index3 .banner .img6 {
        background-image: url(../img/unit3/image6_h5.png);
        background-size: 100% 100%;
    }

    .index3 .banner .img7 {
        background-image: url(../img/unit3/image7_h5.png);
        background-size: 100% 100%;
    }

    .index3 .banner .img8 {
        background-image: url(../img/unit3/image8_h5.png);
        background-size: 100% 100%;
    }

    .index3 .banner .item .name {
        width: 41.6rem;
        height: 16.43rem;
        line-height: 16rem;
        font-size: 2.4rem;
    }

    .index3 .banner {
        padding: 0 10.2rem 0 4.8rem;
    }

    .index3 .banner .index3-pagination {
        gap: 3.2rem;
        margin-top: 4.8rem;
    }

    .index3 .banner .index3-pagination .swiper-pagination-bullet {
        width: 1.6rem;
        height: 1.6rem;
    }

    .index7 .form {
        display: none;
    }

    .index7 {
        background-size: auto 24rem;
        background-position: top center;
    }

    .index7 .continter {
        padding-top: 7.4rem;
        padding-bottom: 8.8rem;
        width: 100%;
        flex-direction: column-reverse;
    }

    .index7 .continter .link {
        margin-top: 2.4rem;
        margin-left: 0;
        justify-content: center;
        gap: 4.8rem;
    }

    .index7 .continter .link .icon {
        width: 3.2rem;
        height: 3.2rem;
    }

    .index7 .continter .copyRight {
        font-size: 2.4rem;
        text-align: center;
    }

    .index4 .continter > .title,
    .index5 .continter > .title,
    .index6 .continter > .title {
        padding-top: 14rem;
        height: 27.8rem;
        font-size: 2.4rem;
        background: url(../img/title_h5.png) no-repeat;
        background-size: 100% auto;
    }

    .index5 .continter {
        height: 124.6rem;
    }

    .index5 .gamePlayer {
        top: 0;
        width: 60rem;
        height: 80rem;
    }

    .index5 video {
        object-fit: cover;
    }

    .index5 .gamePlayer .playBtn {
        width: 12rem;
        height: 12rem;
    }

    .index6 {
        height: 149.6rem;
        background-image: url(../img/unit6/bg_h5.png);
        background-size: cover;
    }

    .index6 .pop {
        display: block !important;
    }

    .index6 .box .right,
    .index6 .box .left,
    .index6 .iconBase,
    .index6 .closeBtn,
    .index6 .pop .bg {
        display: none;
    }

    .index6 .pop .prevBtn,
    .index6 .pop .nextBtn {
        width: 9.6rem;
        height: 9.6rem;
        top: 38rem;
        z-index: 3;
    }

    .index6 .pop .prevBtn {
        left: 3.2rem;
    }

    .index6 .pop .nextBtn {
        right: 3.2rem;
    }

    .swiper-index6 .content {
        gap: 6.8rem;
        flex-direction: column;
    }

    .swiper-index6 {
        margin-top: 30rem;
    }

    .swiper-index6 .content .icon {
        transform: scale(1.167);
    }

    .swiper-index6 .content .text {
        width: 52rem;
    }

    .swiper-index6 .content .text .title {
        font-size: 4rem;
    }

    .swiper-index6 .content .text .label {
        margin-top: 1.6rem;
        font-size: 3.2rem;
        line-height: 140%;
    }

    .swiper-index6 .content .text .label br {
        display: none;
    }

    .index4 .list .item .bottom {
        display: none;
    }

    .index4 .continter {
        height: 126rem;
    }

    .index4 .list {
        position: relative;
        width: 100%;
        padding: 0 10.2rem 9.8rem 4.8rem;
        /* transform: scale(2); */
        margin-top: 0rem;
    }

    .index4 .list .item {
        width: 60rem;
        height: 80rem;
        padding: 4rem 6rem 8rem 6rem;
        background-size: 100% 100%;
    }

    .index4 .list .item .icon {
        width: 28rem;
        height: 28rem;
        margin: 0 auto;
    }

    .index4 .list .item .title {
        font-size: 4rem;
        margin-top: 1.6rem;
    }

    .index4 .list .item .label:first-child {
        font-size: 3.2rem;
    }

    .index4 .list .item .label {
        width: 100%;
        font-size: 3rem !important;
        margin-top: .8rem;
        font-family: Baskerville;
        line-height: 140%;
    }

    .swiper-index4 {
        overflow: visible;
    }

    .index4 .list .item .label br {
        display: none;
    }

    .index4 .smallBg {
        display: none;
    }

    .index4-pagination {
        gap: 12rem;
    }

    .index4-pagination .swiper-pagination-bullet {
        width: 5.2082rem;
        height: 5.2082rem;
        background: none;
        border-radius: 0;
    }

    .index4-pagination .swiper-pagination-bullet:nth-child(1) {
        background: url(../img/unit4/item_icon2.png) no-repeat;
        background-size: 100% 100%;
    }

    .index4-pagination .swiper-pagination-bullet:nth-child(2) {
        background: url(../img/unit4/item_icon4.png) no-repeat;
        background-size: 100% 100%;
    }

    .index4-pagination .swiper-pagination-bullet:nth-child(3) {
        background: url(../img/unit4/item_icon6.png) no-repeat;
        background-size: 100% 100%;
    }
}
