html {
	font-size: 10px;
}

html,
body {
	width: 100%;
	/* height: 100%; */
	overscroll-behavior: none;
	font-family: 'Baskerville';
	/* overflow: hidden; */
}

html,
body,
body * {
	margin: 0;
	padding: .01px;
	box-sizing: border-box;
	-webkit-tag-highlight-color: transparent;
	-webkit-text-size-adjust: none;
}

section {
	position: relative;
}

video,
img,
a {
	color: inherit;
	display: block;
	text-decoration: none;
	object-fit: contain;
	-webkit-tap-highlight-color: transparent;
}

video,
a {
	cursor: pointer;
}

p {
	word-wrap: break-word;
}

svg {
	-webkit-tag-highlight-color: transparent;
}

textarea,
input {
	width: 100%;
	border: none;
	background-color: transparent;
	outline: none;
}

p::selection,
img::selection {
	color: #ffffff;
	background-color: #000000;
}

.swiper {
	overflow: hidden;
	width: 100%;
	height: 100%;
}